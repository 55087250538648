<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-img
      v-if="logoCompteClient && afficherLogoCC"
      :max-width="!$vuetify.breakpoint.smAndDown ? '200px' : '80px'"
      :src="logoCompteClient"
      contain
    />
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="
        $vuetify.breakpoint.smAndDown
          ? setDrawer(!drawer)
          : $emit('input', !value)
      "
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down police-toucan"
      style="font-size:2em"
      v-text="$route.name"
    />

    <v-spacer />

    <div class="mx-3" />
    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-web</v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        dense
      >
        <app-bar-item>
          <v-list-item-content
            data-cy="btnDeconnexion"
            @click="onChangerLangue('fr')"
          >
            <v-list-item-title>
              Français
            </v-list-item-title>
          </v-list-item-content>
        </app-bar-item>
        <app-bar-item>
          <v-list-item-content
            data-cy="btnDeconnexion"
            @click="onChangerLangue('en')"
          >
            <v-list-item-title>
              English
            </v-list-item-title>
          </v-list-item-content>
        </app-bar-item>
      </v-list>
    </v-menu>

    <v-menu
      v-model="dialogueHoraire"
      bottom
      left
      width="500"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('vision.horaire') }}
          <v-icon right>
            mdi-calendar-text
          </v-icon>
        </v-btn>
      </template>

      <v-card class="mt-0 mb-0">
        <v-card-title class="text-h4 success lighten-1 elevation-6 pb-3 pt-3 white--text">
          {{ $t('vision.horaire') }}
        </v-card-title>

        <v-card-text class="pa-0">
          <v-divider />
          <v-simple-table class="">
            <tbody>
              <tr
                v-for="conference in conferences"
                :key="conference.id"
              >
                <td>
                  <v-img
                    :src="urlImage(conference)"
                    width="48"
                  />
                </td>
                <td>{{ conference.dateOuverture | moment("LLLL") }}</td>
                <td v-text="conference.nom" />
                <td>
                  <v-btn
                    v-if="gererAccesConferences(conference)"
                    x-small
                    color="primary"
                    @click="consulterConference(conference)"
                  >
                    <v-icon small>
                      mdi-play
                    </v-icon>
                    {{ $t('vision.acceder') }}
                  </v-btn>
                  <v-btn
                    v-if="!gererAccesConferences(conference)"
                    x-small
                    color="primary"
                    disabled
                  >
                    <v-icon small>
                      mdi-play
                    </v-icon>
                    {{ $t('vision.a_venir') }}
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>

        <!-- <v-divider /> -->

        <v-card-actions>
          <!-- <v-spacer /> -->
          <v-btn
            color="primary"
            block
            text
            @click="dialogueHoraire = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          data-cy="btnMenuProfil"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        flat
        nav
      >
        <!-- <app-bar-item to="/pop">
          <v-list-item-title>Profil</v-list-item-title>
        </app-bar-item>
        <app-bar-item to="/pop">
          <v-list-item-title>Paramètres</v-list-item-title>
        </app-bar-item> -->
        <!-- <v-divider class="mb-2 mt-2" /> -->
        <app-bar-item>
          <v-list-item-content
            data-cy="btnDeconnexion"
            @click="déconnecter()"
          >
            <v-list-item-title>
              {{ $t('general.deconnecter') }}
            </v-list-item-title>
          </v-list-item-content>
        </app-bar-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>

  // Components
  import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { mapState, mapMutations } from 'vuex'
  import restApiService from '@/services/restApiService.js'
  import horlogeService from '@/services/horlogeService.js'
  import i18n from '@/i18n.js'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(
                  VListItem,
                  {
                    attrs: this.$attrs,
                    class: {
                      'black--text': !hover,
                      'white--text secondary elevation-12': hover,
                    },
                    props: {
                      activeClass: '',
                      dark: hover,
                      link: true,
                      ...this.$attrs,
                    },
                  },
                  this.$slots.default,
                )
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notifications: [
        'Mike John Responded to your email',
        'You have 5 new tasks',
        "You're now friends with Andrew",
        'Another Notification',
        'Another one',
      ],
      profile: [
        { title: 'Profile' },
        { title: 'Settings' },
        { divider: true },
        { title: 'Log out' },
      ],
      conferences: [],
      dialogueHoraire: false,
      evenementActifId: -1,
      logoCompteClient: null,
      listePagesAvecLogo: [
        i18n.t('presentation.salle_des_présentations'),
        i18n.t('menu.salle_accueil'),
        i18n.t('diffusion.visionnement'),
        i18n.t('conferencier.les_conférenciers'),
        i18n.t('evenement.programme'),
        i18n.t('exposant.salon_des_exposants'),
        i18n.t('kiosque.kiosque'),
        i18n.t('kiosque.renseignements'),
        i18n.t('salle_p.salle_privee'),
        i18n.t('general.aide'),
      ],
    }),
    computed: {
      ...mapState(['drawer']),
      evenementActifState() {
        return this.$store.state.evenementActif
      },
      afficherLogoCC () {
        return this.listePagesAvecLogo.includes(this.$route.name)
      },
    },
    watch: {
      $route(to, from) {
        // Si on fait un router.push avec le même chemin mais des params différents vue ne réagis pas. (voir docs)
        if (to.name === from.name && to.path !== from.path) {
          this.$router.go(0)
        }
      },
      evenementActifState() {
        this.evenementActifId = this.$store.state.evenementActif?.id
        if (this.evenementActifId) {
          this.getConferences()
          this.stopInterval()
          this.startInterval()
          this.majLogoCompteClient()
        }
      },
    },
    created () {
      this.evenementActifId = this.$store.state.evenementActif?.id
      this.getConferences()
      this.startInterval()
      this.majLogoCompteClient()
    },
    methods: {
      getConferences() {
        console.log('evenementActifId', this.evenementActifId)
        if (this.evenementActifId !== -1) {
          restApiService.get(`/api/webUser/listeConferencesAccessibles/${this.$store.state.evenementActif.id}`)
            .then((result) => {
              this.conferences = result.data.sort((a, b) =>
                Date.parse(a.dateDebut) - Date.parse(b.dateDebut),
              ).filter(c => Date.parse(c.dateFin) > horlogeService.maintenant())
            })
            .catch((erreur) => alert(erreur))
        }
      },
      gererConferences() {
        this.conferences = this.conferences.filter(c => Date.parse(c.dateFin) > horlogeService.maintenant())
      },
      gererAccesConferences(conference) {
        return Date.parse(conference.dateOuverture) < horlogeService.maintenant()
      },
      onChangerLangue(langue) {
        this.$store.commit('SET_APP_LANGUAGE', langue)
      },
      consulterConference (conference) {
        this.dialogueHoraire = false
        this.$router.push({ name: 'Visionnement', params: { idConference: conference.id } })
      },
      urlImage (conference) {
        return restApiService.mediaUrl(conference.mediaImage)
      },
      startInterval () {
        this.getConferencesInterval = setInterval(() => {
          if (this.$store.state.evenementActif) {
            this.gererConferences()
          }
        }, 10000)
      },
      stopInterval () {
        clearInterval(this.getConferencesInterval)
      },
      déconnecter () {
        // Aller à la page de deconnexion
        this.$router.push({ path: '/orka/deconnexion' })
      },
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      majLogoCompteClient() {
        const urlLogoCompteClient = this.$store.state.evenementActif.compteClient.mediaImage
        if (urlLogoCompteClient) {
          restApiService.getMedia(urlLogoCompteClient).then(m => {
            this.logoCompteClient = m.data
            this.logoCompteClient = `data:${this.logoCompteClient.dataContentType};base64, ${this.logoCompteClient.data}`
          })
        } else {
          this.logoCompteClient = null
        }
      },
    },
  }
</script>
<style>
.v-toolbar__content {
    padding: 0px 15px 0 5px !important;
}
</style>
