var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"id":"app-bar","absolute":"","app":"","color":"transparent","flat":"","height":"75"}},[(_vm.logoCompteClient && _vm.afficherLogoCC)?_c('v-img',{attrs:{"max-width":!_vm.$vuetify.breakpoint.smAndDown ? '200px' : '80px',"src":_vm.logoCompteClient,"contain":""}}):_vm._e(),_c('v-btn',{staticClass:"mr-3",attrs:{"elevation":"1","fab":"","small":""},on:{"click":function($event){_vm.$vuetify.breakpoint.smAndDown
        ? _vm.setDrawer(!_vm.drawer)
        : _vm.$emit('input', !_vm.value)}}},[(_vm.value)?_c('v-icon',[_vm._v(" mdi-view-quilt ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1),_c('v-toolbar-title',{staticClass:"hidden-sm-and-down police-toucan",staticStyle:{"font-size":"2em"},domProps:{"textContent":_vm._s(_vm.$route.name)}}),_c('v-spacer'),_c('div',{staticClass:"mx-3"}),_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-web")])],1)]}}])},[_c('v-list',{attrs:{"tile":false,"dense":""}},[_c('app-bar-item',[_c('v-list-item-content',{attrs:{"data-cy":"btnDeconnexion"},on:{"click":function($event){return _vm.onChangerLangue('fr')}}},[_c('v-list-item-title',[_vm._v(" Français ")])],1)],1),_c('app-bar-item',[_c('v-list-item-content',{attrs:{"data-cy":"btnDeconnexion"},on:{"click":function($event){return _vm.onChangerLangue('en')}}},[_c('v-list-item-title',[_vm._v(" English ")])],1)],1)],1)],1),_c('v-menu',{attrs:{"bottom":"","left":"","width":"500","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('vision.horaire'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-calendar-text ")])],1)]}}]),model:{value:(_vm.dialogueHoraire),callback:function ($$v) {_vm.dialogueHoraire=$$v},expression:"dialogueHoraire"}},[_c('v-card',{staticClass:"mt-0 mb-0"},[_c('v-card-title',{staticClass:"text-h4 success lighten-1 elevation-6 pb-3 pt-3 white--text"},[_vm._v(" "+_vm._s(_vm.$t('vision.horaire'))+" ")]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-divider'),_c('v-simple-table',{},[_c('tbody',_vm._l((_vm.conferences),function(conference){return _c('tr',{key:conference.id},[_c('td',[_c('v-img',{attrs:{"src":_vm.urlImage(conference),"width":"48"}})],1),_c('td',[_vm._v(_vm._s(_vm._f("moment")(conference.dateOuverture,"LLLL")))]),_c('td',{domProps:{"textContent":_vm._s(conference.nom)}}),_c('td',[(_vm.gererAccesConferences(conference))?_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.consulterConference(conference)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-play ")]),_vm._v(" "+_vm._s(_vm.$t('vision.acceder'))+" ")],1):_vm._e(),(!_vm.gererAccesConferences(conference))?_c('v-btn',{attrs:{"x-small":"","color":"primary","disabled":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-play ")]),_vm._v(" "+_vm._s(_vm.$t('vision.a_venir'))+" ")],1):_vm._e()],1)])}),0)])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","block":"","text":""},on:{"click":function($event){_vm.dialogueHoraire = false}}},[_vm._v(" Ok ")])],1)],1)],1),_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":"","data-cy":"btnMenuProfil"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}])},[_c('v-list',{attrs:{"tile":false,"flat":"","nav":""}},[_c('app-bar-item',[_c('v-list-item-content',{attrs:{"data-cy":"btnDeconnexion"},on:{"click":function($event){_vm.déconnecter()}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('general.deconnecter'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }